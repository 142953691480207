<script lang="ts">
    import { Spinner } from '../';
    import { twMerge } from 'tailwind-merge';
    import type { ButtonType } from '../types';

    type ButtonColor = keyof typeof colorClasses;

    export let pill = false;
    export let outline = false;
    export let size: 'xs' | 'base' | 'xl' = 'base';
    export let href: string | undefined = undefined;
    export let type: ButtonType = 'button';
    export let color: ButtonColor = 'dark';
    export let shadow = false;
    export let onlyIcon = false;
    export let loading = false;

    const colorClasses = {
        dark: 'text-white bg-gray-900 enabled:hover:bg-gray-700 disabled:bg-gray-200',
        gray: 'text-gray-900 bg-gray-200 enabled:hover:bg-gray-300 disabled:text-gray-400 disabled:bg-gray-100',
        green: 'text-white bg-green-500 enabled:hover:bg-green-800 disabled:bg-green-100',
        red: 'text-white bg-red-500 enabled:hover:bg-red-800 disabled:bg-red-100',
        primary: 'text-white bg-primary-500 enabled:hover:bg-primary-800 disabled:bg-primary-100',
    };

    const coloredFocusClasses = {
        dark: 'focus:ring-gray-300',
        gray: 'focus:ring-gray-100',
        green: 'focus:ring-green-300',
        red: 'focus:ring-red-300',
        primary: 'focus:ring-primary-300',
    };

    const coloredShadowClasses = {
        dark: 'shadow-gray-500/50',
        gray: 'shadow-gray-500/50',
        green: 'shadow-green-500/50',
        red: 'shadow-red-500/50',
        primary: 'shadow-primary-500/50',
    };

    const outlineClasses = {
        dark: 'text-gray-900 enabled:hover:text-white disabled:text-gray-400 border border-gray-500 enabled:hover:bg-gray-700 enabled:hover:border-gray-700 disabled:border-gray-200 focus:bg-gray-700 focus:text-white',
        gray: 'text-gray-900 disabled:text-gray-300 border border-gray-300 enabled:hover:bg-gray-300 enabled:hover:border-gray-100 disabled:border-gray-100 focus:bg-gray-300',
        green: 'text-green-700 enabled:hover:text-white disabled:text-green-400 border border-green-500 enabled:hover:bg-green-800 enabled:hover:border-green-800 disabled:border-green-100 focus:text-white focus:bg-green-500',
        red: 'text-red-700 enabled:hover:text-white disabled:text-red-400 border border-red-500 enabled:hover:bg-red-800 enabled:hover:border-red-800 disabled:border-red-100 focus:text-white focus:bg-red-500',
        primary:
            'text-primary-700 enabled:hover:text-white disabled:text-primary-400 border border-primary-500 enabled:hover:bg-primary-800 enabled:hover:border-primary-800 disabled:border-primary-100 focus:text-white focus:bg-primary-500',
    };

    $: sizeClasses = {
        xs: twMerge('text-xs font-semibold leading-[1.125rem] h-8 gap-1', onlyIcon ? 'w-8' : 'min-w-[5rem] p-2'),
        base: twMerge('text-sm font-semibold leading-[1.125rem] h-12 gap-1', onlyIcon ? 'w-12' : 'min-w-[7.5rem] px-4 py-[0.9375rem]'),
        xl: twMerge('text-sm font-semibold leading-[1.125rem] h-12 gap-2', onlyIcon ? 'w-12' : 'min-w-[18.75rem] px-6 py-[0.9375rem]'),
    };

    $: baseClasses = ['text-center font-medium', 'focus:ring-4', 'outline-none focus:outline-none', 'inline-flex items-center justify-center', sizeClasses[size], coloredFocusClasses[color]];

    $: pillClass = pill ? 'rounded-full' : 'rounded-lg';
    $: colorStyleClass = outline ? outlineClasses[color] : colorClasses[color];
    $: shadowClass = shadow ? `shadow-lg ${coloredShadowClasses[color]}` : '';
    $: disabledClass = $$props.disabled ? 'cursor-not-allowed' : '';

    $: buttonClass = twMerge(...baseClasses, pillClass, colorStyleClass, shadowClass, disabledClass, $$props.class);
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->

<svelte:element
    this={href ? 'a' : 'button'}
    type={href ? undefined : type}
    {href}
    {...$$restProps}
    disabled={$$props.disabled || loading}
    class={buttonClass}
    on:click
    on:change
    on:keydown
    on:keyup
    on:touchstart
    on:touchend
    on:touchcancel
    on:mouseenter
    on:mouseleave
>
    {#if loading}
        <Spinner size="[1.125rem]" />
    {:else}
        <slot />
    {/if}
</svelte:element>

<!--
  @component
  ## Features
  [Go to Button](https://flowbite-svelte.com/docs/components/button)
  - Setup
  - Default button
  - Button with link
  - Button pills
  - Gradient monochrome
  - Gradient duotone
  - Gradient outline
  - Colored shadows
  - Outline buttons
  - Button sizes
  - Buttons with icon
  - Button with label
  - Icon buttons
  - Loader
  - Disabled  
  ## Props
  @prop pill: boolean = false;
  @prop outline: boolean = false;
  @prop size: 'xs' | 'base' | 'xl' = 'base';
  @prop href: string | undefined = undefined;
  @prop btnClass: string | undefined = undefined;
  @prop type: ButtonType = 'button';
  @prop color: ButtonColor = 'dark';
  @prop shadow: boolean = false;
  @prop onlyIcon: boolean = false;
  @prop loading: boolean = false;
  ## Event
  - on:click
  - on:change
  - on:keydown
  - on:keyup
  - on:touchstart
  - on:touchend
  - on:touchcancel
  - on:mouseenter
  - on:mouseleave
  ## Example
  ```
  <script>
    import { Button } from '@allibee/components/basic';
  </script>

  <Button>Default</Button>
  <Button color="alternative">Alternative</Button>
  <Button color="dark">Dark</Button>
  <Button color="light">Light</Button>  
  ```
-->
